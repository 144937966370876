import { render, staticRenderFns } from "./ProteinInfo.vue?vue&type=template&id=84afa36e&scoped=true&"
import script from "./ProteinInfo.vue?vue&type=script&lang=js&"
export * from "./ProteinInfo.vue?vue&type=script&lang=js&"
import style0 from "./ProteinInfo.vue?vue&type=style&index=0&id=84afa36e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84afa36e",
  null
  
)

export default component.exports